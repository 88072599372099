import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { Links } from '../utils/navigation/navigation';

const Custom404 = () => {
  const router = useRouter();

  useEffect(() => {
    router.replace(Links.Home);
  });

  return null;
};

export default Custom404;
